import React from 'react'

import { VoucherPage } from '../components'

class Vouchers extends React.Component {
  render() {
    return <VoucherPage />
  }
}

export default Vouchers
